import { FC } from 'react'
import { useCarouselContext } from '../constants'

interface StepProps {
  index: number
  onClick: () => void
  selected: boolean
}

interface StepsProps {
  renderStep: (props: StepProps) => JSX.Element
}

export const Steps: FC<StepsProps> = ({ renderStep }) => {
  const { scrollTo, scrollSnaps, selectedStep } = useCarouselContext()

  if (scrollSnaps.length <= 1) {
    return null
  }

  return (
    <>
      {scrollSnaps.map((_, index) =>
        renderStep({ index, selected: index === selectedStep, onClick: () => scrollTo(index) }),
      )}
    </>
  )
}
