import { FC } from 'react'
import clsx from 'clsx'
import { useEmblaCarouselMultiple } from '../constants'
import { Slide } from './slide'

interface SlideListProps {
  slides: JSX.Element[]
  className?: string
}

export const SlideList: FC<SlideListProps> = ({ slides, className }) => {
  const viewportRef = useEmblaCarouselMultiple()
  const itemsList = slides.map((slide, i) => <Slide key={i}>{slide}</Slide>)

  return (
    <div className={clsx('overflow-hidden w-full', className)} ref={viewportRef}>
      <div className="flex min-w-full min-h-full">{itemsList}</div>
    </div>
  )
}
