import { FC } from 'react'
import clsx from 'clsx'
import { CaretLeft, CaretRight, Quotes } from 'phosphor-react'
import { ImageAsset, Section as ISection } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import Carousel from '@ignition/library/components/molecules/carousel'
import Section from '../molecules/section'
import ImageElement from './image'

interface Testimonial {
  author: string
  position: string
  photo: ImageAsset
  text: string
}

interface TestimonialsProps {
  testimonials: Testimonial[]
  section: ISection
}

const Testimonials: FC<TestimonialsProps> = ({ testimonials, section }) => {
  if (!testimonials?.length) {
    return null
  }

  const authors = testimonials.map((testimonial, i) => (
    <blockquote key={i} className="mr-1">
      <div>
        <Quotes className="h-12 w-12 text-white opacity-25" weight="fill" />
        <p key={i} className="mt-6 text-xl sm:text-2xl font-medium text-white">
          {testimonial.text}
        </p>
      </div>
      <footer className="mt-6">
        <p className="font-medium text-white">{testimonial.author}</p>
        <p className="font-medium text-white/60">{testimonial.position}</p>
      </footer>
    </blockquote>
  ))

  const images = testimonials.map(({ photo }, i) => (
    <ImageElement
      key={i}
      image={{ ...photo, customRatio: '1:1' }}
      fill
      className="block relative lg:h-full lg:w-full "
    />
  ))

  return (
    <>
      <Section section={section} contentClassName="bg-primary">
        <Carousel>
          <Grid columns={{ base: 1, lg: 3 }} gap={{ base: 0, lg: 8 }}>
            <Grid.Item className="relative -mt-8 lg:-mb-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                <div className="relative overflow-hidden rounded-xl shadow-xl lg:h-full">
                  <Carousel.SlideList className="lg:h-full" slides={images} />
                </div>
              </div>
            </Grid.Item>
            <Grid.Item colspan={{ lg: 2 }} className="mt-12 lg:m-0 lg:pl-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:pt-20 lg:pb-10">
                <Carousel.SlideList slides={authors} />
                <div className="flex justify-center">
                  <nav className="pt-10 mb-8">
                    <Carousel.PrevButton>
                      {({ onClick }) => (
                        <CaretLeft
                          className="h-12 w-12 text-white/60 hover:text-white inline hover:cursor-pointer"
                          aria-hidden="true"
                          onClick={onClick}
                        />
                      )}
                    </Carousel.PrevButton>
                    <Carousel.Steps
                      renderStep={({ index, selected, onClick }) => (
                        <strong
                          key={index}
                          onClick={onClick}
                          className={clsx(
                            'w-4 inline-block hover:text-white hover:cursor-pointer',
                            selected ? 'text-white' : 'text-white/60',
                          )}
                        >
                          &bull;
                        </strong>
                      )}
                    />
                    <Carousel.NextButton>
                      {({ onClick }) => (
                        <CaretRight
                          className="h-12 w-12 text-white/60 hover:text-white inline hover:cursor-pointer"
                          aria-hidden="true"
                          onClick={onClick}
                        />
                      )}
                    </Carousel.NextButton>
                  </nav>
                </div>
              </div>
            </Grid.Item>
          </Grid>
        </Carousel>
      </Section>
    </>
  )
}

export default Testimonials
