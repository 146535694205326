import { createContext, useCallback, useContext, useEffect } from 'react'
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react'

export const defaultContextState = {
  canScrollPrev: false,
  canScrollNext: false,
  draggable: false,
  loop: false,
  onSelectScrollSnap: () => {},
  scrollSnaps: [],
  setScrollSnaps: () => {},
  selectedStep: 0,
  scrollNext: () => {},
  scrollPrev: () => {},
  scrollTo: () => {},
  registerEmblaApi: () => null,
  unregisterEmblaApi: () => {},
}

interface ICarouselContext {
  canScrollPrev?: boolean
  canScrollNext?: boolean
  draggable: boolean
  loop: boolean
  onSelectScrollSnap: (selectScrollSnap: number) => void
  scrollSnaps: number[]
  setScrollSnaps: (scrollSnaps: number[]) => void
  selectedStep: number
  scrollNext: () => void
  scrollPrev: () => void
  scrollTo: (index: number) => void
  registerEmblaApi: (emblaApi: EmblaCarouselType | undefined) => number | null
  unregisterEmblaApi: (id: number) => void
}

export const CarouselContext = createContext<ICarouselContext>(defaultContextState)
export const useCarouselContext = () => {
  const context = useContext(CarouselContext)
  return context
}

export const useEmblaCarouselMultiple = () => {
  const { loop, draggable, registerEmblaApi, unregisterEmblaApi, onSelectScrollSnap, setScrollSnaps } =
    useCarouselContext()

  const [viewportRef, emblaApi] = useEmblaCarousel({
    draggable,
    loop,
    skipSnaps: false,
  })

  const onSelect = useCallback(() => {
    if (emblaApi) {
      onSelectScrollSnap(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi, onSelectScrollSnap])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    const id = registerEmblaApi(emblaApi)

    emblaApi.on('select', onSelect)
    setScrollSnaps(emblaApi.scrollSnapList())
    return () => {
      if (id) {
        unregisterEmblaApi(id)
      }
    }
  }, [emblaApi, registerEmblaApi, unregisterEmblaApi, onSelect, setScrollSnaps])

  return viewportRef
}
