import { ReactNode, useCallback, useEffect, useState } from 'react'
import { EmblaCarouselType } from 'embla-carousel-react'
import logger from '../../../lib/logger'
import { CarouselContext } from './constants'
import { NextButton, PrevButton, SlideList, Steps } from './elements'

export interface CarouselProps {
  className?: string
  children?: ReactNode
  draggable?: boolean
  loop?: boolean
  autoplay?: boolean
  autoplayDelay?: number
}

export const Carousel = ({
  className,
  children,
  draggable = false,
  loop = true,
  autoplay = false,
  autoplayDelay = 4000,
}: CarouselProps) => {
  const [emblaApis, setEmblaApis] = useState<EmblaCarouselType[]>([])
  const [selectedStep, setSelectedStep] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [timer, setTimer] = useState<number | undefined>()

  const registerEmblaApi = useCallback(
    (emblaApi: EmblaCarouselType | undefined) => {
      if (!emblaApi) {
        return null
      }
      const id = null
      setEmblaApis((emblaApis) => [...emblaApis, emblaApi])
      return id
    },
    [setEmblaApis],
  )

  const unregisterEmblaApi = useCallback(
    (id: number) => {
      setEmblaApis((emblaApis) => {
        return emblaApis.filter((_, i) => i !== id)
      })
    },
    [setEmblaApis],
  )

  const scrollNext = useCallback(
    (autoPplay = false) => {
      emblaApis.forEach((emblaApi) => emblaApi?.scrollNext())
      if (!autoPplay) {
        setTimer(undefined)
      }
    },
    [emblaApis],
  )

  const scrollPrev = useCallback(
    (autoPplay = false) => {
      emblaApis.forEach((api) => api?.scrollPrev())
      if (!autoPplay) {
        setTimer(undefined)
      }
    },
    [emblaApis],
  )

  const scrollTo = useCallback(
    (index: number, autoPlay = false) => {
      emblaApis.forEach((api) => api?.scrollTo(index))
      if (!autoPlay) {
        setTimer(undefined)
      }
    },
    [emblaApis],
  )

  useEffect(() => {
    logger.info('UseEffect')
    if (autoplay && scrollSnaps.length > 1 && !timer) {
      const intervalId = window.setInterval(() => {
        scrollNext(true)
      }, autoplayDelay)
      setTimer(intervalId)
    }

    return () => {
      window?.clearTimeout(timer)
    }
  }, [autoplay, scrollSnaps, autoplayDelay, scrollNext, timer])

  const canScrollNext = emblaApis?.length
    ? emblaApis.reduce((acc, curr) => acc && curr?.canScrollNext(), true)
    : undefined
  const canScrollPrev = emblaApis?.length
    ? emblaApis.reduce((acc, curr) => acc && curr?.canScrollNext(), true)
    : undefined

  return (
    <CarouselContext.Provider
      value={{
        canScrollNext,
        canScrollPrev,
        draggable,
        loop,
        onSelectScrollSnap: setSelectedStep,
        scrollNext,
        scrollPrev,
        scrollTo,
        selectedStep,
        scrollSnaps,
        setScrollSnaps,
        registerEmblaApi,
        unregisterEmblaApi,
      }}
    >
      <div className={className}>{children}</div>
    </CarouselContext.Provider>
  )
}

Carousel.PrevButton = PrevButton
Carousel.NextButton = NextButton
Carousel.SlideList = SlideList
Carousel.Steps = Steps
